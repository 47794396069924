<template>
  <div class="mt-4">
    <div class="vx-row">
      <div class="vx-col w-full">
        <ValidationErrors :errors="errors"/>
      </div>
    </div>

    <!--File List-->
    <div class="vx-row mb-3">
      <div class="vx-col sm:w-12/12 w-full">
        <label class="text-xs">Files</label>
        <vs-table :data="files" stripe class="fix-table-input-background" :class="{ 'animate-pulse': isLoadingSpkFiles }">
          <template slot="thead">
            <vs-th class="whitespace-no-wrap">#</vs-th>
            <vs-th class="whitespace-no-wrap">Nama File</vs-th>
            <vs-th class="whitespace-no-wrap">Lihat File</vs-th>
            <vs-th class="whitespace-no-wrap">Keterangan</vs-th>
          </template>
          <template slot-scope="{data}">
            <vs-tr v-for="(item, index) in data" :key="index" class="text-sm">
              <vs-td>{{ index + 1 }}</vs-td>
              <vs-td>
                <vs-input v-if="item.isUnsaved" v-model="item.nama" type="text"/>
                <span v-else>{{ item.nama }}</span>
              </vs-td>
              <vs-td>
                <vs-input v-if="item.isUnsaved" class="w-full" type="file" accept="image/*" v-model="item.fileTmp" @change="item.file = $event.target.files[0]"/>
                <a v-else :href="item.file_url" target="_blank">
                  <vs-chip color="primary"><vs-avatar icon-pack="feather" icon="icon-file"/><span>File</span></vs-chip>
                </a>
              </vs-td>
              <vs-td>{{ item.keterangan }}</vs-td>
            </vs-tr>
          </template>
        </vs-table>
      </div>
    </div>

    <div class="vx-row float-right mt-5">
      <div class="vx-col w-full flex">
        <vs-button @click="prev" icon-pack="feather" icon="icon-arrow-left" class="mr-3">Prev</vs-button>
      </div>
    </div>

  </div>
</template>

<script>
import CaraBayarRepository from '@/repositories/master/cara-bayar-repository'
import SpkRepository from '@/repositories/proyek/spk-repository'
import ValidationErrors from '@/views/components/validation-errors/ValidationErrors'
import _ from 'lodash'

export default {
  name: 'TabAkunting',
  props: ['isActive', 'idSpk', 'idSpkD', 'spkD'],
  components: {
    ValidationErrors
  },
  watch: {
    isActive (active) {
      if (active && this.isFirstActive) {
        this.getAllCaraBayar()
        this.getSpkFiles()
        this.data.id_cara_bayar = _.cloneDeep(this.spkD.id_cara_bayar)
        this.isFirstActive = false
      }
    }
  },
  data () {
    return {
      isFirstActive: true,
      isLoading: false,
      isLoadingSpkFiles: false,
      errors: null,
      data: {},
      files: [],
      caraBayars: []
    }
  },
  methods: {
    getAllCaraBayar () {
      CaraBayarRepository.getAll()
        .then(response => {
          this.caraBayars = response.data.data
        })
        .catch(error => {
          console.log(error)
        })
    },

    getSpkFiles () {
      this.isLoadingSpkFiles = true

      SpkRepository.getSpkFiles(this.idSpk)
        .then(response => {
          this.files = response.data.data
        })
        .catch(error => {
          console.log(error)
        })
        .finally(() => {
          this.isLoadingSpkFiles = false
        })
    },

    onSuccess () {
      this.getSpkFiles()
      this.notifySuccess('Data berhasil disimpan.')
    },

    prev () {
      const currentActiveTab = this.$store.state.approvals.spkTerminApproval.activeTab
      this.$store.commit('approvals/spkTerminApproval/SET_ACTIVE_TAB', currentActiveTab - 1)
    }
  }
}
</script>
